import { useEffect, useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import api from "api/v1-jwt/config/api";
import setAccessToken from "pages/plaid/actions/setAccesToken";
import { toast } from "react-toastify";
import { useAuth } from "providers/auth";

const usePlaidLinkWithStatus = ({
  payment_method_status,
  method_type,
  access_token,
  defaultPaymentMethod,
  onSuccess,
  name_on_account,
}) => {
  const [linkToken, setLinkToken] = useState(null);
  const [loadingToken, setLoadingToken] = useState(false);
  const [error, setError] = useState(null);
  const { cognitoId } = useAuth();

  useEffect(() => {
    const fetchLinkToken = async () => {
      if (method_type === "PLAID" && payment_method_status !== "active") {
        setLoadingToken(true);
        try {
          let route = `/platform/payment_methods/plaid/link-token`;
          let payload = {
            user_id: cognitoId,
          };
          if (access_token != "") {
            payload.access_token = access_token;
          }
          const { data } = await api.post(route, payload);
          const linkToken = data.link_token;
          setLinkToken(linkToken);
        } catch (err) {
          console.error(err);
          setError(err);
        } finally {
          setLoadingToken(false);
        }
      }
    };

    fetchLinkToken();
  }, [cognitoId, payment_method_status, method_type]);

  const { open: openPlaidLink, ready: readyPlaidLink } = usePlaidLink({
    token: linkToken,
    onSuccess: async (access_token, metadata) => {
      try {
        console.log(access_token, payment_method_status, method_type);
        const newPaymentMethod = await setAccessToken(
          access_token,
          metadata,
          cognitoId,
          name_on_account,
          defaultPaymentMethod
        );

        onSuccess(newPaymentMethod, access_token, metadata);
      } catch (err) {
        console.error(err);
        toast.error(`Failed to connect your bank account.`);
      }
    },
    onExit: (error, metadata) => {
      if (error != null && error.error_code === "INVALID_LINK_TOKEN") {
        toast.error(
          "Something went wrong linking your account. Please try connecting again."
        );
      }

      if (error != null) {
        toast.error(error.display_message);

        console.log(
          `USER ${cognitoId} EXITED PLAID WITH ERROR TYPE ${error.error_type} :: CODE ${error.error_code}`
        );
        console.log(
          "================================================================="
        );
        console.log("ERROR MESSAGE::", error.error_message);
        console.log(
          "================================================================="
        );
        console.log("ERROR OBJECT::", error);
      }
      // to handle other error codes, see https://plaid.com/docs/errors/
    },

    onEvent: async (eventName, metadata) => {
      if (eventName === "ERROR" && metadata.error_code != null) {
        console.log(
          `PLAID EVENT WITH ERROR TYPE ${metadata.error_type} :: CODE ${metadata.error_code}`
        );
        console.log(
          "================================================================="
        );
        console.log("ERROR MESSAGE::", metadata.error_message);
        console.log(
          "================================================================="
        );
        console.log("EVENT METADATA OBJECT::", metadata);
      }
    },
  });

  return { linkToken, loadingToken, error, openPlaidLink, readyPlaidLink };
};

export default usePlaidLinkWithStatus;
