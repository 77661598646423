import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik'; 
import { BasicModal, Button, textStyles } from 'ui';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { CircularProgress, FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { toast } from 'react-toastify';
import { useStep } from '../hooks/useStep';
import { customTimeout } from 'utils/functions';


export const SubmitForm = () => {
  const history=useHistory()
  const [openModal, setOpenModal]=useState(false)
  const [openCancelModal, setOpenCancelModal]=useState(false)
  const [businessAgreement,setBusinessAgreement ]=useState(false)
  const [grasshoperPrivacyPolicy,setGrasshoperPrivacyPolicy ]=useState(false)
  const { handlePost}= useStep(6)
  const [loading,setLoading]=useState(false)

  const handleCloseCancelModal=()=>{
    setOpenCancelModal(false)
  }
  
  return (
    <Formik
      initialValues={{ agree: 'Yes' }} 
      onSubmit={() => {
        const handleFormModal=()=>{
          setOpenModal(true)
        }
        if(businessAgreement && grasshoperPrivacyPolicy ){
          setLoading(true)
          handlePost({
            accepted_legal_terms: true,
            is_submitted: true
          })

          customTimeout(()=>{
            setLoading(false)
            handleFormModal()
          },10000)

        }else{
          toast.error("You need to agree in order to continue.")
        }
      }}
    >
      <Form>
        <Container>
          <Text>By checking the box beside each document and pressing “Submit”, you acknowledge that you are applying for a deposit account with Grasshopper Bank, N.A. Member FDIC, and that you agree to the documents below. You are also authorizing us to share your personal information with Grasshopper Bank for its use in accordance with the terms of its privacy policy set forth below. You also consent to Grasshopper Bank sharing information about you and your deposit account with Pocketbook.
        </Text>
      <OptionsList>
        <li>
        <FormControlLabel
          control={
            <Checkbox
              name="business-agreement"
              checked={businessAgreement}
              onChange={() => setBusinessAgreement(!businessAgreement)}
            />
          }
          label={<LabelText>Grasshopper Bank’s <StyledLink href='https://cdn.pocketbook.tech/pb_tech/BaaS_End_User_Master_Service_Agreement.pdf' target='blank'>Business Account Agreement</StyledLink></LabelText>}
        />
        </li>
        <li>
        <FormControlLabel
          control={
            <Checkbox
              name="grasshoper-privacy-policy"
              checked={grasshoperPrivacyPolicy}
              onChange={() => setGrasshoperPrivacyPolicy(!grasshoperPrivacyPolicy)}
            />
          }
          label={<LabelText>Grasshopper Bank’s <StyledLink href='https://www.grasshopper.bank/privacy/' target='blank'>Privacy Policy</StyledLink></LabelText>}
        />
        </li>
      </OptionsList>
          <ActionButtons>
            <Button variant='white' onClick={(e)=>{
                  e.preventDefault()
                  setOpenCancelModal(true)
                }}>Cancel</Button>
              <Button variant='green' type="submit">{loading?<CircularProgress style={{'color': '#FFFFFF'}} size={12}/>:"Submit"}</Button>
          </ActionButtons> 
          <BasicModal
          title={'Are you sure you want to cancel setup?'}
          text={"If you cancel, you’ll need to start over."}
          open={openCancelModal}
          buttonText={'Yes, cancel setup'}
          onButtonClick={()=>history.push("/dashboard")}
          handleClose={handleCloseCancelModal}
        />
          <BasicModal
            title={"Your application has been submitted"}
            text={"You will receive an email in the next 24 hours with an update on the status of your application"}
            buttonText={"Go back to Dashboard"}
            open={openModal}
            handleClose={()=>setOpenModal(false)}
            onButtonClick={()=>history.push("/dashboard")}
          />
        </Container>
      </Form>
    </Formik>
  );
}

const Container = styled.div`
  margin-top: 34px;
`;

const Text = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: #939393;
  font-weight: 400;
  text-align: justify;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 30px;
`;

const OptionsList=styled.ul`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const LabelText=styled.div`
${textStyles.body.b2}
`

const StyledLink=styled.a`
  text-decoration: underline;
`