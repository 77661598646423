import axios from "axios";

const getAuthInfo = async (cognitoId) => {
  try {
    const apiUrl = `${process.env.REACT_APP_PBTECH_API_ENDPOINT}/${process.env.REACT_APP_PBTECH_API_VERSION}/plaid/auth`;

    const headers = {
      "x-api-key": process.env.REACT_APP_PBTECH_API_KEY,
      "Content-Type": "application/json",
    };

    const responseData = await axios.post(
      apiUrl,
      { cognito_id: cognitoId },
      { headers }
    );
    return responseData;
  } catch (err) {
    console.error("Error fetching access token:", err);
    if (err.response) {
      console.error("Server responded with:", err.response.data);
    }
  }
};

export default getAuthInfo;
