import { LinkBankStepper } from "pages/dashboard/dashboard";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog";
import { useTenancy } from "hooks/tenancy/use-tenancy";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

export function PlanLockModal() {
  const { data: tenancy } = useTenancy();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    if (
      (path === "/payment-methods" ||
        path === "/bills" ||
        path === "/move-money") &&
      tenancy?.record_status !== "active"
    ) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [tenancy, location.pathname]);

  return (
    <Dialog
      open={open}
      onOpenChange={(change) => {
        setOpen(change);
        if (change === false) {
          history.replace("/");
        }
      }}
    >
      <DialogContent className="max-w-2xl">
        <DialogHeader className="mb-4">
          <DialogTitle className="flex justify-center items-center">
            <ExclamationTriangleIcon className="w-6 h-6 mr-2 text-yellow-600" />{" "}
            Some features are still locked
          </DialogTitle>
          <DialogDescription className=" text-center">
            Finish the steps below to unlock all of your Pocketbook's great
            features like the ability to move money and pay bills.
          </DialogDescription>
        </DialogHeader>
        <LinkBankStepper />
      </DialogContent>
    </Dialog>
  );
}
