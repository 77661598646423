import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BsChevronLeft, BsCheck, BsX } from "react-icons/bs";
import { toast } from "react-toastify";
import logo from "assets/images/pocketbook-logo.png";
import pocketbook_logo from "assets/images/logo.png";
import {
  convertPhoneNumber,
  isValidLowercaseInPassword,
  isValidNumberInPassword,
  isValidPasswordLength,
  isValidSpecialCharacterInPassword,
  isValidUppercaseInPassword,
} from "scripts/functions";
import {
  StyledAuthInput,
  StyledAuthPassword,
} from "pages/utils/styled-auth-input";
import { getInvoiceById } from "pages/invoices/actions";
import { getContractById } from "api/v1-jwt/contract/contract";
import * as actionTypes from "pages/invoices/actionsTypes";
import * as quoteActionTypes from "pages/contracts/actionTypes";

import { createNewUser } from "./actions";
import {
  PocketbookLogo,
  RightSection,
  ContactBtn,
  FormGroupSection,
  FormSubmitBtn,
  LeftSection,
  SignupFormSection,
  SignupFormTitle,
  SignupWrapper,
  LeftHeader,
  SignupLeftFooter,
  RightImage,
  SetPasswordBackBtn,
  ChecklistSection,
  ChecklistLabel,
} from "./styled";
import { useAuth } from "providers/auth";

const CustomerSetPassword = () => {
  const history = useHistory();
  const { signin } = useAuth();
  const dispatch = useDispatch();
  const { accountInfo, settingData } = useSelector((state) => state.account);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [validated, setValidated] = useState({
    password: null,
    confirmPassword: null,
  });
  const [errMessage, setErrMessage] = useState({
    confirmPassword: "Does not match. Try again.",
  });

  useEffect(() => {
    if (!accountInfo || !accountInfo.email || accountInfo.email === "")
      history.push("/signup");
    if (accountInfo && accountInfo.email) setEmail(accountInfo.email);
  }, [accountInfo, history]);

  const validatePassword = (value) => {
    if (!isValidPasswordLength(value)) {
      setValidated((prevState) => ({ ...prevState, password: false }));
    } else if (!isValidNumberInPassword(value)) {
      setValidated((prevState) => ({ ...prevState, password: false }));
    } else if (!isValidSpecialCharacterInPassword(value)) {
      setValidated((prevState) => ({ ...prevState, password: false }));
    } else if (!isValidUppercaseInPassword(value)) {
      setValidated((prevState) => ({ ...prevState, password: false }));
    } else if (!isValidLowercaseInPassword(value)) {
      setValidated((prevState) => ({ ...prevState, password: false }));
    } else {
      setValidated((prevState) => ({ ...prevState, password: true }));
    }

    if (confirmPassword !== "") {
      if (value === confirmPassword) {
        setValidated((prevState) => ({ ...prevState, confirmPassword: true }));
      } else {
        setValidated((prevState) => ({ ...prevState, confirmPassword: false }));
        setErrMessage((prevState) => ({
          ...prevState,
          confirmPassword: "Does not match. Try again",
        }));
      }
    }
  };
  const validateConfirmPassword = (value) => {
    if (value === "") {
      setErrMessage((prevState) => ({
        ...prevState,
        confirmPassword: "Confirm Password is required!",
      }));
      setValidated((prevState) => ({ ...prevState, confirmPassword: false }));
    } else if (value !== password) {
      setErrMessage((prevState) => ({
        ...prevState,
        confirmPassword: "Does not match. Try again",
      }));
      setValidated((prevState) => ({ ...prevState, confirmPassword: false }));
    } else {
      setValidated((prevState) => ({ ...prevState, confirmPassword: true }));
    }
  };

  const handlePasswordChange = (event) => {
    setIsSubmitted(false);
    validatePassword(event.target.value);
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setIsSubmitted(false);
    validateConfirmPassword(event.target.value);
    setConfirmPassword(event.target.value);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const onSubmit = async () => {
    const contactId = localStorage.getItem("contactId");
    console.log("PASSWORD CONTACT ID::", contactId);
    if (!contactId || contactId === "") {
      toast.error("Contact ID does not exist.");
    } else {
      setIsSubmitted(true);
      setValidated({
        password: validated.password === null ? false : validated.password,
        confirmPassword:
          validated.confirmPassword === null
            ? false
            : validated.confirmPassword,
      });
      if (validated.password && validated.confirmPassword) {
        let payload;
        if (accountInfo.accountType === "Business") {
          payload = {
            account_type: "business",
            first_name: accountInfo.firstName,
            last_name: accountInfo.lastName,
            accepted_pb_tos_pp: true,
            opt_in_text_messages: accountInfo.optInTextMessages,
            user_type: "customer",
            password: password,
            business_name: accountInfo.businessName,
            business_email: accountInfo.email,
            business_phone: convertPhoneNumber(accountInfo.phone),
          };
        } else {
          payload = {
            account_type: "individual",
            first_name: accountInfo.firstName,
            last_name: accountInfo.lastName,
            accepted_pb_tos_pp: true,
            opt_in_text_messages: accountInfo.optInTextMessages,
            user_type: "customer",
            password: password,
            email_address: accountInfo.email,
            phone_number: convertPhoneNumber(accountInfo.phone),
          };
        }

        await createNewUser(payload, contactId).then((res) => {
          if (res && res.platform_client_id) {
            toast.success("Your account has been created.");
            handleLogin();
          } else {
            toast.error("Error has occcured while creating account.");
          }
        });
      }
    }
  };

  // TODO: All this is done is two places. Clean up!
  const handleLogin = async () => {
    const payload = {
      username: accountInfo.email,
      password: password,
    };

    await signin(payload, {
      onSuccess: async (data) => {
        console.log("[LOGIN SUCCESS]::::::: ", data);
        if (data && data.cognito_auth_info) {
          // const accessToken = data.cognito_auth_info.RefreshToken;
          // localStorage.setItem("AuthToken", accessToken);
          // dispatch({
          //   type: authActionTypes.SET_TOKEN,
          //   payload: accessToken,
          // });
        }

        if (data && data.cognito_id) {
          // localStorage.setItem("CognitoId", data.cognito_id);
          // dispatch({
          //     type: authActionTypes.LOGGED_IN_USER_ID,
          //     payload: data.cognito_id,
          // });
        }
        if (data && data.client_tenancy_info) {
          const userType = data.client_tenancy_info.user_type;
          // localStorage.setItem("UserRole", userType);
          // dispatch({
          //   type: authActionTypes.USER_ROLE,
          //   payload: userType,
          // });

          // Maybe missing a redirect if no invoice found?
          if (userType === "customer") {
            const invoiceId = localStorage.getItem("invoiceId");
            const quoteId = localStorage.getItem("quoteId");
            if (
              invoiceId &&
              invoiceId !== "undefined" &&
              invoiceId !== "null"
            ) {
              await getInvoiceById(invoiceId).then((res) => {
                if (res && res.invoice_id) {
                  dispatch({
                    type: actionTypes.SELECTED_INVOICE,
                    payload: res,
                  });
                  history.push(`/invoices/detail/${res.invoice_id}`);
                }
              });
            } else if (
              quoteId &&
              quoteId !== "undefined" &&
              quoteId !== "null"
            ) {
              await getContractById(quoteId).then((res) => {
                if (res && res.contract_id) {
                  dispatch({
                    type: quoteActionTypes.SELECTED_QUOTE,
                    payload: res,
                  });
                  history.push(`/quotes/${res.contract_id}`);
                }
              });
            }
          }
        }
      },

      onError: (error, payload) => {
        console.log("[LOGIN ERROR]::::::: ", error);
        toast.error("Login failed. Try again in a moment.");
      },
    });
  };

  return (
    <SignupWrapper>
      <LeftSection>
        <LeftHeader>
          <PocketbookLogo
            src={logo}
            alt="logo"
            onClick={() => history.push("/")}
          />
        </LeftHeader>
        <SignupFormSection>
          <div style={{ width: "100%" }}>
            <SetPasswordBackBtn
              onClick={() => {
                history.push("/signup");
              }}
            >
              <BsChevronLeft /> Back
            </SetPasswordBackBtn>
            <SignupFormTitle>Set your password</SignupFormTitle>
            <FormGroupSection className="signup-form">
              <StyledAuthInput
                name="email"
                value={email}
                placeholder="Email"
                validation={true}
                disabled
              />
              <StyledAuthPassword
                name="password"
                value={password}
                placeholder="Password"
                validation={validated.password}
                isSubmitted={isSubmitted}
                handleChange={handlePasswordChange}
                showPassword={showPassword}
                handleShowPassword={handleClickShowPassword}
              />
              <ChecklistSection>
                {isValidPasswordLength(password) ? (
                  <div className="flex">
                    <BsCheck style={{ color: "#00cd4c" }} />
                    <ChecklistLabel style={{ color: "#000000" }}>
                      Minimum length of 12 characters
                    </ChecklistLabel>
                  </div>
                ) : (
                  <div className="flex">
                    <BsX />
                    <ChecklistLabel>
                      Minimum length of 12 characters
                    </ChecklistLabel>
                  </div>
                )}
                {isValidLowercaseInPassword(password) ? (
                  <div className="flex">
                    <BsCheck style={{ color: "#00cd4c" }} />
                    <ChecklistLabel style={{ color: "#000000" }}>
                      Must contain a lowercase letter
                    </ChecklistLabel>
                  </div>
                ) : (
                  <div className="flex">
                    <BsX />
                    <ChecklistLabel>
                      Must contain a lowercase letter
                    </ChecklistLabel>
                  </div>
                )}
                {isValidUppercaseInPassword(password) ? (
                  <div className="flex">
                    <BsCheck style={{ color: "#00cd4c" }} />
                    <ChecklistLabel style={{ color: "#000000" }}>
                      Must contain an uppercase letter
                    </ChecklistLabel>
                  </div>
                ) : (
                  <div className="flex">
                    <BsX />
                    <ChecklistLabel>
                      Must contain an uppercase letter
                    </ChecklistLabel>
                  </div>
                )}
                {isValidNumberInPassword(password) ? (
                  <div className="flex">
                    <BsCheck style={{ color: "#00cd4c" }} />
                    <ChecklistLabel style={{ color: "#000000" }}>
                      Must contain a number
                    </ChecklistLabel>
                  </div>
                ) : (
                  <div className="flex">
                    <BsX />
                    <ChecklistLabel>Must contain a number</ChecklistLabel>
                  </div>
                )}
                {isValidSpecialCharacterInPassword(password) ? (
                  <div className="flex">
                    <BsCheck style={{ color: "#00cd4c" }} />
                    <ChecklistLabel style={{ color: "#000000" }}>
                      Must contain a special character
                    </ChecklistLabel>
                  </div>
                ) : (
                  <div className="flex">
                    <BsX />
                    <ChecklistLabel>
                      Must contain a special character
                    </ChecklistLabel>
                  </div>
                )}
              </ChecklistSection>
              <StyledAuthPassword
                name="password"
                value={confirmPassword}
                placeholder="Retype your password"
                validation={validated.confirmPassword}
                isSubmitted={isSubmitted}
                handleChange={handleConfirmPasswordChange}
                showPassword={showConfirmPassword}
                handleShowPassword={handleClickShowConfirmPassword}
                errMsg={errMessage.confirmPassword}
              />
              <FormSubmitBtn onClick={onSubmit}>Submit</FormSubmitBtn>
            </FormGroupSection>
          </div>
        </SignupFormSection>
        <SignupLeftFooter>
          <ContactBtn>
            Questions? Feel free to{" "}
            <a
              href="mailto:support@pocketbook.tech"
              style={{ color: "#939393" }}
            >
              contact our team.
            </a>
          </ContactBtn>
        </SignupLeftFooter>
      </LeftSection>
      <RightSection style={{ background: `${settingData?.primary_color}` }}>
        <RightImage
          src={
            settingData?.logo
              ? `https://cdn.pocketbook.tech/${settingData?.logo}`
              : pocketbook_logo
          }
          alt="signup-right-image"
        />
      </RightSection>
    </SignupWrapper>
  );
};

export default CustomerSetPassword;
