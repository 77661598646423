import { Dialog } from "@radix-ui/react-dialog";
import usePlaidLinkWithStatus from "pages/plaid/hooks/usePlaidLink";
import { useEffect } from "react";
import { toast } from "react-toastify";

const Plaid = ({
  open,
  onClose,
  method_type,
  payment_method_status,
  access_token,
  setPaymentList,
  dashboard = false,
  onSuccess,
  name_on_account,
}) => {
  const onPlaidLinkSuccess = (paymentMethod, _accessToken, _metadata) => {
    setPaymentList((prev) => {
      const index = prev.findIndex(
        (item) => item.payment_method_id === paymentMethod.payment_method_id
      );

      if (index !== -1) {
        // Replace the existing item with the same ID
        const updated = [...prev];
        updated[index] = paymentMethod;
        return updated;
      }

      // If not found, add the new payment method
      return [...prev, paymentMethod];
    });

    toast.success(`Your bank account has been connected.`);
  };

  const { linkToken, loadingToken, error, openPlaidLink, readyPlaidLink } =
    usePlaidLinkWithStatus({
      payment_method_status,
      method_type,
      access_token,
      defaultPaymentMethod: dashboard ? true : false,
      onSuccess: dashboard ? onSuccess : onPlaidLinkSuccess,
      name_on_account: name_on_account,
    });

  const handleOpenPlaidLink = () => {
    openPlaidLink();
    onClose();
  };

  useEffect(() => {
    if (readyPlaidLink) {
      handleOpenPlaidLink();
    }
  }, [readyPlaidLink]);

  return <Dialog open={open} onClose={onClose} />;
};

export default Plaid;
