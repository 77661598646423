import api from "api/v1-jwt/config/api";
import { toast } from "react-toastify";

export const getBondToken = async ({ userType = "business", id }) => {
  const payload = {
    [userType === "business" ? "business_id" : "customer_id"]: id,
  };
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_PBTECH_API_KEY,
    },
    body: JSON.stringify(payload),
  };
  const response = await fetch(
    `${process.env.REACT_APP_PBTECH_API_ENDPOINT}/${process.env.REACT_APP_PBTECH_API_VERSION}/bond/get_temp_auth`,
    options
  );
  const res = await response.json();
  return res;
};

export const accountLink = async (
  user_type,
  linked_account_id,
  businessId,
  userId
) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_PBTECH_API_KEY,
    },
    body: JSON.stringify({
      customer_or_business_id: businessId,
      cognito_id: userId || clientCognitoId,
      is_business: user_type === "business" ? true : false,
      linked_account_id: linked_account_id,
      account_type: user_type,
    }),
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_PBTECH_API_ENDPOINT}/${process.env.REACT_APP_PBTECH_API_VERSION}/bond/confirm_account_link`,
      options
    );
    const res = await response.json();
    return res;
  } catch (err) {
    throw err;
  }
};

export const getTokenWithInquiry = async (inquiryId) => {
  const options = {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_PBTECH_API_KEY,
    },
    body: JSON.stringify({ inquiry_id: inquiryId }),
  };
  const response = await fetch(
    `${process.env.REACT_APP_PBTECH_API_ENDPOINT}/${process.env.REACT_APP_PBTECH_API_VERSION}/bond/get_token_with_inquiry`,
    options
  );
  const res = await response.json();
  return res;
};

export const getProfileInfo = async (userId) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.get(
      `/platform/profile/${userId || clientCognitoId}`
    );

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getBondBalance = async () => {
  const clientCognitoId = localStorage.getItem("PLATFORM_ID");

  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.get(
      `/platform/accounts/${JSON.parse(clientCognitoId)}/balance`
    );

    return data;
  } catch (error) {
    console.log(error);
    return error.response?.data;
  }
};

export const updateProfile = async (payload, userId) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.patch(
      `/platform/profile/${userId || clientCognitoId}`,
      payload
    );

    return data;
  } catch (error) {
    console.log(error);
    return error.response?.data;
  }
};

export const getClientInfo = async (user_id) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.get(
      `/tenancy/clients/${clientCognitoId}?user_id=${
        user_id || clientCognitoId
      }`
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
};
