import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

import { Button } from "components/ui/button-tw";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { useContact } from "hooks/contact/use-contact";
import { Loader2 } from "lucide-react";
import moment from "moment";
import { ConfirmDialog } from "components/confirm-dialog";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import axios from "axios";
import { toast } from "react-toastify";
import api from "api/v1-jwt/config/api";

const amountRules = {
  required: {
    message: "Amount is required",
    value: true,
  },
};
const routingValidation = {
  required: "Routing number is required",
  validate: (value) => {
    if (!/^\d+$/.test(value)) {
      return "Routing number must be numeric";
    } else if (value.length !== 9) {
      return "Routing number must be 9 digits";
    }
    return undefined; // Validation passed
  },
};

const accountValidation = {
  required: "Account number is required",
  validate: (value) =>
    /^\d{5,17}$/.test(value) || "Account number must be between 5-17 digits",
};

const accountRules = {
  required: {
    message: "Account number is required",
    value: true,
  },
};

const routingRules = {
  required: {
    message: "Routing number is required",
    value: true,
  },
};

const accountTypeRules = {
  required: {
    message: "Account type is required",
    value: true,
  },
};

const saveBankInfo = async (name_on_account, values, contact_id) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const bank_data = {
    name_on_account,
    ach: {
      account_number: values.account,
      routing_number: values.routing,
      account_type: values.accountType,
    },
  };

  const { data } = await api.post(
    `/platform/contacts/add_bank/${clientCognitoId}/${contact_id}`,
    bank_data
  );
  return data;
};

const transferMoney = async (payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data, status } = await api.post(
      `/platform/transfers/move_money/third_party/${clientCognitoId}`,
      payload
    );

    if (status !== 201)
      throw new Error(`Bill payment failed with status ${status}`);
    return data;
  } catch (error) {
    throw error;
  }
};

function formatBalance(input) {
  const number = parseFloat(input);
  if (isNaN(number)) {
    return "Invalid input";
  }
  return number.toFixed(2);
}

function AddAccountModal({
  open,
  onOpenChange,
  contactId,
  selectedAccount,
  selectedContact,
  setSelectedAccount,
  setPaymentType,
  user_account,
  balance,
}) {
  const history = useHistory();
  const amountValidation = {
    required: "Amount is required",
    validate: {
      isDecimal: (value) =>
        /^\d+(\.\d{2})$/.test(value) || "Amount must contain two decimals",
      balanceSufficient: (value) =>
        parseFloat(value) <= balance.available_balance ||
        "Insufficient balance",
    },
  };
  const {
    handleSubmit,
    register,
    formState: { errors, isValid, isSubmitting },
    getValues,
    reset,
    watch,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      accountType: "checking",
      account: "",
      amount: "",
      routing: "",
    },
  });
  const [confirm, setConfirm] = useState(false);
  const [description, setDescription] = useState("");
  const [amountToPay, setAmountToPay] = useState(0);
  const [isInitialized, setIsInitialized] = useState(false);
  const [newAccount, setNewAccount] = useState(null);

  const { data: contact } = useContact(contactId, {
    enabled: !!contactId,
  });
  const watchedAmount = watch("amount");

  useEffect(() => {
    if (open) {
      reset({
        accountType: "checking",
        account: "",
        amount: "",
        routing: "",
      });
    }
  }, [open, reset]);

  useEffect(() => {
    if (!isInitialized && selectedAccount) {
      reset({
        accountType: selectedAccount?.account_type || "checking",
        account: selectedAccount?.account_num_last_4 || "",
        amount: "0.00",
        routing: selectedAccount?.routing_num_last_4 || "",
      });
      setIsInitialized(true);
    }
  }, [selectedAccount, reset, isInitialized]);

  useEffect(() => {
    // Update the description whenever the amount changes
    const { amount } = getValues();
    if (!contact || !amount) {
      setDescription("asd");
      return;
    }

    const newDescription = `The following payment will be made to ${contact.contact_info.first_name} ${contact.contact_info.last_name}:

    Bank Name: ${selectedAccount?.bank_name}
    Routing #: ****${selectedAccount?.routing_num_last_4}
    Account #: ****${selectedAccount?.account_num_last_4}
    Amount: $${amount}

    Estimated availability of funds will be 2-3 business days.`;

    setDescription(newDescription);
  }, [contact, watchedAmount, selectedAccount]); // Depend on contact and form state

  useEffect(() => {
    const { amount } = getValues();
    if (!contact || !amount) {
      setDescription("asd");
      return;
    }
    const newDescription = `The following payment will be made to ${contact.contact_info.first_name} ${contact.contact_info.last_name}:

    Bank Name: ${newAccount?.bank_name}
    Routing #: ****${newAccount?.routing_num_last_4}
    Account #: ****${newAccount?.account_num_last_4}
    Amount: $${amount}

    Estimated availability of funds will be 2-3 business days.`;

    setDescription(newDescription);
  }, [newAccount]);

  return (
    <>
      <ConfirmDialog
        open={confirm}
        onOpenChange={setConfirm}
        onConfirm={async () => {
          const { amount } = getValues();
          let account;
          if (!selectedAccount && newAccount) {
            account = newAccount;
          } else if (!newAccount && selectedAccount) {
            account = selectedAccount;
          } else if (!selectedAccount && !newAccount) {
            toast.error("Please select an account or add a new one");
            return;
          }
          try {
            const payload = {
              counterparty_id: account.counter_party_id,
              amount: amount,
              third_party_id: contact.contact_id,
            };
            await transferMoney(payload);
            toast.success("Bill payment sent successfully.");
            history.push("/transactions");
          } catch (e) {
            toast.error(e);
          }
        }}
        title="Confirm your payment"
        description={description}
      />
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <form
            onSubmit={handleSubmit(async () => {
              if (!selectedAccount) {
                try {
                  const fullName = `${contact?.contact_info?.first_name} ${contact?.contact_info?.last_name}`;
                  const res = await saveBankInfo(
                    fullName,
                    getValues(),
                    contact.contact_id
                  );
                  if (res.error) {
                    toast.error(`Error with ${res?.error?.field} number`);
                  } else {
                    setPaymentType("pay_someone");
                    setSelectedAccount(res.bank_info);
                    setNewAccount(res.bank_info);
                    setConfirm(true);
                    onOpenChange(false);
                  }
                } catch (e) {
                  reset();
                  toast.error(e);
                  // Update the state to show error to the user
                }
              } else {
                setPaymentType("pay_someone");
                setConfirm(true);
                onOpenChange(false);
              }
            })}
          >
            <DialogHeader>
              <DialogTitle className="text-xl">
                <div>Pay someone from Your Pocketbook</div>
              </DialogTitle>
            </DialogHeader>
            {!contact ? (
              <div className="flex items-center justify-center">
                <Loader2 className="w-8 h-8 animate-spin" />
              </div>
            ) : (
              <div className="space-y-4 pt-2">
                <div className="px-4  sm:grid sm:grid-cols-4 sm:gap-x-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-slate-900">
                    Recipient
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-slate-700 sm:col-span-3 sm:mt-0">
                    {`${contact.contact_info.first_name} ${contact.contact_info.last_name}`}
                  </dd>
                  <dt className="text-sm font-medium leading-6 text-slate-900"></dt>
                  <dd className="mt-1 text-sm leading-6 text-slate-700 sm:col-span-3 sm:mt-0">
                    {`${contact.contact_info.email}`}
                  </dd>
                </div>

                {selectedAccount ? (
                  <>
                    <div className="grid w-full max-w-sm items-center gap-1.5">
                      <Label htmlFor="type">Bank</Label>
                      <div>{selectedAccount?.bank_name}</div>
                    </div>
                    <div className="grid w-full max-w-sm items-center gap-1.5">
                      <Label htmlFor="type">Account type</Label>
                      <div>{selectedAccount?.account_type}</div>
                    </div>
                    <div className="grid w-full max-w-sm items-center gap-1.5">
                      <Label htmlFor="type">Routing #</Label>
                      <div>•••••{selectedAccount?.routing_num_last_4}</div>
                    </div>
                    <div className="grid w-full max-w-sm items-center gap-1.5">
                      <Label htmlFor="type">Account #</Label>
                      <div>•••••{selectedAccount?.account_num_last_4}</div>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="grid w-full max-w-sm items-center gap-1.5">
                      <Label htmlFor="type">Account type</Label>
                      <Controller
                        control={control}
                        name="accountType"
                        render={({ field, fieldState }) => {
                          return (
                            <Select
                              defaultValue={field.value}
                              onValueChange={field.onChange}
                              aria-invalid={fieldState.error ? "true" : "false"}
                            >
                              <SelectTrigger>
                                <SelectValue
                                  placeholder="Account type"
                                  defaultValue={"checking"}
                                />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectGroup>
                                  <SelectItem value="checking" className="">
                                    Individual Checking
                                  </SelectItem>
                                  <SelectItem value="savings" className="">
                                    Individual Savings
                                  </SelectItem>
                                  <SelectItem
                                    value="business_checking"
                                    className=""
                                  >
                                    Business Checking
                                  </SelectItem>
                                  <SelectItem
                                    value="business_savings"
                                    className=""
                                  >
                                    Business Savings
                                  </SelectItem>
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                          );
                        }}
                      />
                      {errors.accountType && (
                        <p role="alert" className="text-sm text-red-600">
                          {errors.accountType.message}
                        </p>
                      )}
                    </div>
                    <div className="grid w-full max-w-sm items-center gap-1.5">
                      <Label htmlFor="routing">Routing #</Label>
                      <Input
                        id="routing"
                        name="routing"
                        type="text"
                        inputmode="numeric"
                        pattern="[0-9]*"
                        placeholder=""
                        maxLength={9}
                        aria-invalid={errors.routing ? "true" : "false"}
                        {...register("routing", routingValidation)}
                      />
                      {errors.routing && (
                        <p role="alert" className="text-sm text-red-600">
                          {errors.routing.message}
                        </p>
                      )}
                    </div>
                    <div className="grid w-full max-w-sm items-center gap-1.5">
                      <Label htmlFor="account">Account #</Label>
                      <Input
                        id="account"
                        placeholder=""
                        type="number"
                        aria-invalid={errors.account ? "true" : "false"}
                        {...register("account", accountValidation)}
                      />
                      {errors.account && (
                        <p role="alert" className="text-sm text-red-600">
                          {errors.account.message}
                        </p>
                      )}
                    </div>
                  </>
                )}

                <div className="grid w-full max-w-sm items-center gap-1.5">
                  <Label htmlFor="amount">Amount</Label>
                  <div>
                    <div className="flex flex-row justify-center h-full gap-1 items-center">
                      <div>$</div>
                      <Input
                        id="amount"
                        type="number"
                        step="1"
                        placeholder="0.00"
                        aria-invalid={errors.amount ? "true" : "false"}
                        {...register("amount", amountValidation)}
                      />
                    </div>
                    {errors.amount && (
                      <p role="alert" className="text-sm text-red-600">
                        {errors.amount.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}
            <DialogFooter className="mt-6">
              <Button
                variant="outline"
                className="w-1/2"
                type="button"
                onClick={() => {
                  reset();
                  onOpenChange(false);
                }}
              >
                Cancel
              </Button>
              <Button className="w-1/2" type="submit" disabled={false}>
                Pay
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AddAccountModal;
