import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Sidebar from "pages/sidebar/sidebar";
import ClientMainHeader from "pages/header";
import { Button } from "components/ui/button-tw";
import { LimitedAccountWarning } from "components/limited-account-warning";
import { PaymentMethodList, MainWrapper } from "components/payment-methods";

const CustomerPaymentMethods = () => {
  const history = useHistory();
  const { settingData } = useSelector((state) => state.account);
  const [shortCut, setShortCut] = useState("");
  const [currentWidth, setCurrentWidth] = useState(0);

  return (
    <div className="h-100">
      <Sidebar currentWidth={currentWidth} setCurrentWidth={setCurrentWidth} />
      <>
        <LimitedAccountWarning />

        <MainWrapper>
          <ClientMainHeader
            title="Payment Methods"
            subTitle="Your connected accounts"
            shortCut={shortCut}
            setShortCut={setShortCut}
          />
          <div className="flex">
            <Button
              variant="outline"
              onClick={() => history.push("/move-money")}
              className="hover:bg-gray-100"
            >
              Move money
            </Button>
          </div>
          <PaymentMethodList settingData={settingData} />
        </MainWrapper>
      </>
    </div>
  );
};

export default CustomerPaymentMethods;
