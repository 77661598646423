import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import CustomerSidebar from "pages/sidebar/customer-sidebar";
import CustomerHeader from "./header";
import { convertThousandSeparator } from "scripts/functions";
import { CalendarDays, UserCircle } from "lucide-react";
import { capitalizeFirstLetter } from "utils/string-manipulation";
import { useProfile } from "hooks/profile/use-profile";
import {
  addresseeArea,
  addresseeBusinessAddr,
  addresseeEmail,
  addresseeNamePriority,
  addresseePhone,
  canInvoiceBePaid,
} from "utils/invoice";
import { customTimeout, formatPhoneNumber } from "utils/functions";
import usePaymentMethods from "hooks/payment/use-payment-methods";
import { useAuth } from "providers/auth";
import { Box, CircularProgress, Modal } from "@mui/material";
import { Button, textStyles } from "ui";
import styled from "styled-components";
import { toast } from "react-toastify";
import api from "api/v1-jwt/config/api";
import { Plaid } from "components/payment-methods";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "24px",
  borderRadius: "15px",
};

const CustomerInvoiceDetail = () => {
  const history = useHistory();
  const { data: profile } = useProfile();
  const { selectedInvoice } = useSelector((state) => state.page);
  const [shortCut, setShortCut] = useState("");
  const [invoiceDetail, setInvoiceDetail] = useState(null);
  const [currentWidth, setCurrentWidth] = useState(0);

  useEffect(() => {
    if (selectedInvoice) {
      setInvoiceDetail(selectedInvoice);
    } else {
      history.push("/invoices");
    }
  }, [selectedInvoice]);

  const handlePayInvoice = () => {
    history.push(`/invoices/pay/${selectedInvoice.invoice_id}`);
  };

  return (
    <PayPalScriptProvider
      options={{
        clientId:
          "AZhsLNS4XKr-xBomen5OSvNQXTkxOkc50Eu7pfWUKCBKGgy0iehII0C6JP_C-Evu06-ne-YNUB7P-w6e",
        disableFunding: "credit",
      }}
    >
      <div className="h-100">
        <CustomerSidebar
          currentWidth={currentWidth}
          setCurrentWidth={setCurrentWidth}
        />
        <div className="py-[20px] px-4 lg:py-8 lg:px-10 xl:py-12 xl:px-[50px] h-[calc(100% - 101px)] lg:h-full lg:ml-[315px] overflow-auto bg-[#f8f8f8] relative">
          <CustomerHeader
            title="Invoice"
            shortCut={shortCut}
            setShortCut={setShortCut}
          />

          <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3 my-8">
            <div className="lg:col-start-3 lg:row-end-1">
              <InvoiceSummary
                invoice={invoiceDetail}
                handlePay={handlePayInvoice}
              />
            </div>

            <div className="-mx-4 px-4 py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-16 xl:pb-20 xl:pt-16">
              <h2 className="text-base font-semibold leading-6 text-gray-900">
                Invoice
              </h2>
              <dl className="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
                <div>
                  <div className="sm:pr-4">
                    <dt className="inline text-gray-500">Sent on</dt>{" "}
                    <dd className="inline text-gray-700">
                      <time>
                        {moment(
                          invoiceDetail?.invoice_info.invoice_send_date
                        ).format("LL")}
                      </time>
                    </dd>
                  </div>
                  <div className="mt-2 sm:mt-0 ">
                    <dt className="inline text-gray-500">Due on</dt>{" "}
                    <dd className="inline text-gray-700">
                      <time>
                        {moment(
                          invoiceDetail?.invoice_info.invoice_due_date
                        ).format("LL")}
                      </time>
                    </dd>
                  </div>
                </div>
                <div className="mt-2 sm:mt-0 sm:pl-4">
                  <dt className="inline text-gray-500">Frequency type: </dt>{" "}
                  <dd className="inline text-gray-700">
                    {invoiceDetail?.invoice_info.invoice_frequency}
                  </dd>
                </div>
                <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
                  <dt className="font-semibold text-gray-900">From</dt>
                  <dd className="mt-2 text-gray-500">
                    <span className="font-medium text-gray-900">
                      {profile?.business_name}
                    </span>
                    <br />
                    {profile?.business_email}
                    <br />
                    {formatPhoneNumber(profile?.business_phone)}
                  </dd>
                </div>
                <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                  <dt className="font-semibold text-gray-900">To</dt>
                  <dd className="mt-2 text-gray-500">
                    <span className="font-medium text-gray-900">
                      {addresseeNamePriority(invoiceDetail)}
                    </span>
                    {/* <br />
                    {addresseeBusinessAddr(invoiceDetail)}
                    <br />
                    {addresseeArea(invoiceDetail)} */}
                    <br />
                    {addresseeEmail(invoiceDetail)}
                    <br />
                    {formatPhoneNumber(addresseePhone(invoiceDetail))}
                    <br />
                  </dd>
                </div>
              </dl>
              <InvoiceDetailsTable invoice={invoiceDetail} />
            </div>
            <div className="lg:col-start-3">
              <InvoiceNotes invoice={invoiceDetail} />
            </div>
          </div>
        </div>
      </div>
    </PayPalScriptProvider>
  );
};

function InvoiceSummary({ invoice }) {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const [showPaymentMethods, setShowPaymentMethods] = useState(false);
  const { cognitoId } = useAuth();
  const { loading, paymentList, setPaymentList } = usePaymentMethods(cognitoId);
  console.log(paymentList);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  let user_id = clientCognitoId;
  let client_id = useAuth().cognitoId;
  const history = useHistory();

  const handlePay = async () => {
    const paymentMethodDetails = paymentList?.find(
      (i) => i.payment_method_id === selectedPaymentMethod
    );
    const payload = {
      payment_type: "ach",
      user_id,
      client_id,
      invoice_id: invoice?.invoice_id,
      // counterparty_id:
      //   paymentMethodDetails.payment_method_data.counter_party_id,
      payment_method_id: paymentMethodDetails.payment_method_id,
    };

    try {
      const { data } = await api.post(
        `/platform/transactions/payments`,
        payload
      );
      toast.success("Payment was made");
      history.push("/invoices");
      return data;
    } catch (error) {
      toast.error("Payment failed");
      console.error("Payment failed:", error);
    }
  };

  return (
    <>
      <h2 className="sr-only">Summary</h2>
      <div className="rounded-lg bg-skin-primary shadow-sm ring-1 ring-gray-900/5">
        <dl className="flex flex-col flex-wrap">
          <div className="flex">
            <div className="flex-auto pl-6 pt-6">
              <dt className="text-sm font-semibold leading-6 text-skin-a11y">
                Amount
              </dt>
              <dd className="mt-1 text-base font-semibold leading-6 text-skin-a11y">
                $
                {convertThousandSeparator(
                  Number(invoice?.invoice_info?.invoice_total)
                )}
              </dd>
            </div>
            <div className="flex-none self-end px-6 pt-4">
              <dt className="sr-only">Status</dt>
              <dd className="rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-black ring-1 ring-inset ring-white">
                {capitalizeFirstLetter(
                  invoice?.invoice_info.invoice_status || ""
                )}
              </dd>
            </div>
          </div>
          <div className="flex flex-col  justify-between lg:mt-6">
            <div className="mt-4 lg:mt-0 flex flex-none gap-x-4 border-t border-gray-900/5 px-6 lg:pt-0 pt-6">
              <dt className="flex-none">
                <span className="sr-only">Client</span>
                <UserCircle className="h-6 w-5 text-white" aria-hidden="true" />
              </dt>
              <dd className="text-sm font-medium leading-6 text-white">
                {invoice?.invoice_info.invoice_to.first_name +
                  " " +
                  invoice?.invoice_info.invoice_to.last_name}
              </dd>
            </div>
            <div className=" mt-4 flex  flex-none gap-x-4 px-6">
              <dt className="flex-none">
                <span className="sr-only">Due date</span>
                <CalendarDays
                  className="h-6 w-5 text-white"
                  aria-hidden="true"
                />
              </dt>
              <dd className="text-sm leading-6 text-white">
                <time>
                  {`Received on ${moment(
                    invoice?.invoice_info.invoice_send_date
                  ).format("LL")}`}
                </time>
              </dd>
            </div>
          </div>
        </dl>
        <div className="mt-6 border-t border-slate-300/20 px-2 py-2">
          {canInvoiceBePaid(invoice) && (
            <>
              {showPaymentMethods ? (
                <PaymentMethodLists
                  paymentMethods={paymentList}
                  setPaymentList={setPaymentList}
                  selectedPaymentMethod={selectedPaymentMethod}
                  setSelectedPaymentMethod={setSelectedPaymentMethod}
                  client_id={client_id}
                  user_id={user_id}
                  invoice_id={invoice?.invoice_id}
                  has_paypal={invoice?.invoice_info.invoice_add_paypal}
                />
              ) : (
                <button
                  className="text-sm font-semibold leading-6 text-skin-a11y px-4 py-3 hover:bg-slate-100/20 rounded-md w-full text-left transition-colors"
                  onClick={
                    !showPaymentMethods
                      ? () => setShowPaymentMethods(true)
                      : () => console.log(selectedPaymentMethod)
                  }
                >
                  Pay invoice <span aria-hidden="true">&rarr;</span>
                </button>
              )}
            </>
          )}
          {selectedPaymentMethod ? (
            <PayButton
              paymentList={paymentList}
              selectedPaymentMethod={selectedPaymentMethod}
              amount={invoice?.invoice_info?.invoice_total}
              handlePay={handlePay}
              selectedInvoice={invoice}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}

const PayButton = ({
  paymentList,
  selectedPaymentMethod,
  handlePay,
  selectedInvoice,
}) => {
  const [isPaying, setIsPaying] = useState(false);
  const [displayPaymentModal, setDisplayPaymentModal] = useState(false);

  const { data: profile } = useProfile();
  const currentDate = new Date();
  const day = currentDate.getDate().toString().padStart(2, "0");
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const year = currentDate.getFullYear();

  const formattedDate = `${month}/${day}/${year}`;

  const total = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(selectedInvoice?.invoice_info?.invoice_total);

  const handleModalClose = () => {
    setDisplayPaymentModal(false);
  };

  const paymentMethodDetails = paymentList?.find(
    (i) => i.payment_method_id === selectedPaymentMethod
  );

  const handlePayment = async () => {
    setIsPaying(true);
    try {
      const res = await handlePay();
    } catch (error) {
      console.error("Payment error:", error);
    }
    handleModalClose();
    setIsPaying(false);
  };

  return (
    <>
      <button
        className="text-sm font-semibold leading-6 text-skin-a11y px-4 py-3 hover:bg-slate-100/20 rounded-md w-full text-left transition-colors border-2 border-white"
        onClick={() => setDisplayPaymentModal(true)}
        disabled={isPaying}
      >
        {`Pay With: ${
          paymentMethodDetails.payment_method_data.institution?.name || ""
        } ${
          paymentMethodDetails.payment_method_data?.ach?.account_type ||
          paymentMethodDetails.payment_method_data.account.name
        } 
          ${
            paymentMethodDetails.payment_method_data?.ach
              ? `- * ${paymentMethodDetails.payment_method_data?.ach?.account_number}`
              : ""
          }
        `}
      </button>
      <Modal
        open={displayPaymentModal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-bills"
      >
        <Box sx={style}>
          <Title>Confirm your payment</Title>
          <Text>
            <b>{total}</b> will be transferred from{" "}
            <b>
              {paymentMethodDetails.payment_method_data?.institution.name || ""}
            </b>{" "}
            account ending in{" "}
            <b>
              *
              {paymentMethodDetails.payment_method_data?.ach?.account_number ||
                paymentMethodDetails.payment_method_data?.account?.mask}
            </b>{" "}
            belonging to{" "}
            <b>{`${selectedInvoice?.invoice_info?.invoice_to?.first_name} ${selectedInvoice?.invoice_info?.invoice_to?.last_name}`}</b>{" "}
            to <b>{profile?.business_name}</b> on <b>{formattedDate}</b>.
          </Text>
          <div style={{ textAlign: "center" }}>
            <Button
              marginBottom={32}
              variant="green"
              onClick={() => handlePayment()}
            >
              Yes, confirm
            </Button>
          </div>
          <BottomText>
            By clicking “Yes, Confirm” you authorize a one-time debit of{" "}
            <b>{total}</b> from your designated account to{" "}
            <b>{profile?.business_name}</b> on <b>{formattedDate}</b>. Your
            authorization is effective immediately and the transfer will be
            processed today or on the next business day if we receive your
            request after our ACH processing deadline.
          </BottomText>
        </Box>
      </Modal>
    </>
  );
};

const PaymentMethodSelect = ({
  type,
  institution,
  last4,
  setSelectedPaymentMethod,
  selectedPaymentMethod,
  paymentMethodId,
}) => {
  // Function to handle click event
  const handleClick = () => {
    setSelectedPaymentMethod(paymentMethodId);
  };

  // Determine if the item is selected
  const isSelected = selectedPaymentMethod === paymentMethodId;

  return (
    <li
      className={`flex-grow border-2 border-white bg-white p-3 ml-0 mb-3 rounded-lg cursor-pointer shadow-sm hover:bg-gray-50`}
      onClick={handleClick}
      style={{ transition: "background-color 0.3s" }}
    >
      <div className="flex justify-between items-center">
        <span className="text-sm font-medium text-gray-700">{`${institution} ${capitalizeFirstLetter(
          type
        )} - ${last4}`}</span>
        {isSelected && (
          <span className="" style={{ color: "black" }}>
            &#10003;
          </span>
        )}
      </div>
    </li>
  );
};

const ConnectPlaidButton = styled.button`
  border: 0;
  background-color: black;
  color: white;
  font-weight: bold;
  padding: 1rem;
  :hover {
    background-color: white;
    color: black;
  }
`;

const PaymentMethodLists = ({
  paymentMethods,
  setPaymentList,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  user_id,
  client_id,
  has_paypal,
  invoice_id,
}) => {
  const history = useHistory();
  const [invoicePaid, setInvoicePaid] = useState(false);
  const { cognitoId } = useAuth();

  const [openPlaid, setOpenPlaid] = useState(false);

  const togglePlaid = () => {
    setOpenPlaid((prev) => !prev);
  };

  // const { linkToken, loading, error } = useLinkToken(cognitoId);

  // const { open, ready } = usePlaidLink({
  //   token: linkToken,
  //   onSuccess: async (public_token, metadata) => {
  //     await setAccessToken(public_token, metadata, cognitoId);
  //     window.location.reload();
  //   },
  // });

  // if (loading) {
  //   return (
  //     <LoadingWrapper>
  //       <CircularProgress disableShrink />
  //     </LoadingWrapper>
  //   );
  // }

  const createOrder = async ({ payment_type = "paypal" }) => {
    try {
      const { data } = await api.post(`/platform/transactions/payments`, {
        payment_type,
        user_id,
        client_id,
        invoice_id,
      });
      return data.order_id;
    } catch (err) {
      console.log(err);
    }
  };

  const captureOrder = async ({ orderID }) => {
    try {
      const { data } = await api.post(
        `/platform/transactions/confirm_payment`,
        {
          order_id: orderID,
          payment_type: "paypal",
          user_id,
          client_id,
          invoice_id,
        }
      );

      setInvoicePaid(true);
      toast.success("Your payment has been submitted.");
      return data.id;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="max-w-md mx-auto mb-4 ml-4 mr-4">
      <h3 className="text-sm font-semibold leading-6 text-skin-a11y mb-4">
        Select a payment method
      </h3>
      <ul className="space-y-4">
        {paymentMethods.map((paymentMethod, index) => (
          <PaymentMethodSelect
            institution={
              paymentMethod.payment_method_data?.institution.name || ""
            }
            type={
              paymentMethod.payment_method_data?.ach?.account_type ||
              paymentMethod.payment_method_data?.account?.subtype
            }
            last4={
              paymentMethod.payment_method_data?.ach?.account_number ||
              paymentMethod.payment_method_data?.account?.mask
            }
            setSelectedPaymentMethod={setSelectedPaymentMethod}
            selectedPaymentMethod={selectedPaymentMethod}
            paymentMethodId={paymentMethod.payment_method_id}
          />
        ))}
        {paymentMethods.length == 0 ? (
          <button
            type="button"
            className=" bg-gray-100 text-black font-medium font-sans border shadow-sm rounded-sm w-full py-2 hover:bg-gray-200"
            onClick={() => togglePlaid()}
          >
            Connect Bank to Pay
          </button>
        ) : null}
        {has_paypal ? (
          <PayPalButtons
            style={{
              layout: "horizontal",
              color: "white",
              shape: "rect",
              tagline: false,
            }}
            createOrder={createOrder}
            onApprove={captureOrder}
          />
        ) : null}
      </ul>
      {openPlaid ? (
        <Plaid
          open={openPlaid}
          onClose={() => setOpenPlaid(false)}
          method_type={"PLAID"}
          setPaymentList={setPaymentList}
          payment_method_status={""}
        />
      ) : null}
    </div>
  );
};

function InvoiceDetailsTable({ invoice }) {
  return (
    <table className="mt-16 w-full whitespace-nowrap text-left text-sm leading-6">
      <colgroup>
        <col className="w-full" />
        <col />
        <col />
        <col />
      </colgroup>
      <thead className="border-b border-gray-200 text-gray-900">
        <tr>
          <th scope="col" className="px-0 py-3 font-semibold">
            Line Items
          </th>
          <th
            scope="col"
            className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell"
          >
            Units
          </th>
          <th
            scope="col"
            className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell"
          >
            Rate
          </th>
          <th scope="col" className="py-3 pl-8 pr-0 text-right font-semibold">
            Price
          </th>
        </tr>
      </thead>
      <tbody>
        {(invoice?.invoice_info.invoice_line_items || []).map((item, i) => (
          <tr key={i} className="border-b border-gray-100">
            <td className="max-w-0 px-0 py-5 align-top">
              <div className="truncate font-medium text-gray-900">
                {item.description}
              </div>
              {/* <div className="truncate text-gray-500">
                      {item.description}
                    </div> */}
            </td>
            <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
              {item.quantity}
            </td>
            <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
              ${convertThousandSeparator(Number(item.unit_price))}
            </td>
            <td className="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
              ${convertThousandSeparator(Number(item.item_total))}
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th
            scope="row"
            className="px-0 pb-0 pt-6 font-normal text-gray-700 sm:hidden"
          >
            Subtotal
          </th>
          <th
            scope="row"
            colSpan={3}
            className="hidden px-0 pb-0 pt-6 text-right font-normal text-gray-700 sm:table-cell"
          >
            Subtotal
          </th>
          <td className="pb-0 pl-8 pr-0 pt-6 text-right tabular-nums text-gray-900">
            $
            {convertThousandSeparator(
              Number(invoice?.invoice_info?.invoice_subtotal)
            )}
          </td>
        </tr>
        <tr>
          <th scope="row" className="pt-4 font-normal text-gray-700 sm:hidden">
            Tax
          </th>
          <th
            scope="row"
            colSpan={3}
            className="hidden pt-4 text-right font-normal text-gray-700 sm:table-cell"
          >
            Tax
          </th>
          <td className="pb-0 pl-8 pr-0 pt-4 text-right tabular-nums text-gray-900">
            $
            {convertThousandSeparator(
              Number(invoice?.invoice_info.invoice_tax_amount)
            )}
          </td>
        </tr>
        {invoice?.invoice_info.invoice_pass_processing_fee && (
          <tr>
            <th
              scope="row"
              className="pt-4 font-normal text-gray-700 sm:hidden"
            >
              Fee
            </th>
            <th
              scope="row"
              colSpan={3}
              className="hidden pt-4 text-right font-normal text-gray-700 sm:table-cell"
            >
              Fee
            </th>
            <td className="pb-0 pl-8 pr-0 pt-4 text-right tabular-nums text-gray-900">
              $
              {convertThousandSeparator(
                Number(invoice?.invoice_info.invoice_processing_fee)
              )}
            </td>
          </tr>
        )}
        <tr>
          <th
            scope="row"
            className="pt-4 font-semibold text-gray-900 sm:hidden"
          >
            Total
          </th>
          <th
            scope="row"
            colSpan={3}
            className="hidden pt-4 text-right font-semibold text-gray-900 sm:table-cell"
          >
            Total
          </th>
          <td className="pb-0 pl-8 pr-0 pt-4 text-right font-semibold tabular-nums text-gray-900">
            $
            {convertThousandSeparator(
              Number(invoice?.invoice_info?.invoice_total)
            )}
          </td>
        </tr>
      </tfoot>
    </table>
  );
}

function InvoiceNotes({ invoice }) {
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6 space-y-2">
        <h2 className="text-sm font-semibold leading-6 text-gray-900">Notes</h2>
        {invoice?.invoice_info.invoice_notes ? (
          <p className="text-sm text-slate-700">
            {invoice?.invoice_info.invoice_notes}
          </p>
        ) : (
          <p className="text-sm text-slate-700">
            There are no notes for this invoice
          </p>
        )}
      </div>
    </div>
  );
}

const Title = styled.div`
  ${textStyles.title.h3};
`;

const Text = styled.div`
  ${textStyles.body.b2};
  margin-bottom: 32px;
`;

const BottomText = styled.div`
  ${textStyles.body.b2}
`;

export default CustomerInvoiceDetail;
