import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { CircularProgress, ThemeProvider } from "@mui/material";

import { useAuth } from "providers/auth";

import { MainLoadingWrapper } from "pages/styled";
import "react-toastify/dist/ReactToastify.min.css";
import "./App.css";
import { GuestRoutes, CustomerRoutes, ClientRoutes } from "routes";
import { Route, Switch } from "react-router-dom";
import { usePlatformSetup } from "hooks/platform/use-platform-setup";
import NotFound from "pages/not-found/not-found";
import InvoiceSlideIn from "components/invoices/invoice-slide-in";
import ContactDrawer from "components/contact/contact-drawer";
import BillDrawer from "components/bill/bill-drawer";
import { PlanLockModal } from "components/plan-lock-modal";

const App = () => {
  const { platformId, theme, error } = usePlatformSetup();
  const { userRole, token } = useAuth();
  const [platformReady, setPlatformReady] = useState(false);

  useEffect(() => {
    let timer;
    if (platformId) {
      timer = setTimeout(() => {
        setPlatformReady(true);
      }, 2000);
    }

    return () => clearTimeout(timer);
  }, [platformId]);

  if (!platformReady && error?.response?.status !== 404) {
    return (
      <MainLoadingWrapper>
        <CircularProgress disableShrink />
      </MainLoadingWrapper>
    );
  }

  return (
    <div style={{ height: "100vh", backgroundColor: "#f8f8f8" }}>
      <Router>
        <ThemeProvider theme={theme}>
          <ToastContainer
            hideProgressBar
            position="top-right"
            autoClose={2000}
          />
          <InvoiceSlideIn />
          <ContactDrawer />
          <BillDrawer />
          <PlanLockModal />

          {error?.response?.status === 404 ? (
            <Route path="*" component={NotFound} />
          ) : (
            <>
              {!token && <GuestRoutes />}

              {token && userRole !== "customer" && <ClientRoutes />}

              {token && userRole === "customer" && <CustomerRoutes />}
            </>
          )}
        </ThemeProvider>
      </Router>
    </div>
  );
};

export default App;
