import api from "../config/api";

export const getContracts = async ({ id, type = "client" }) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const isCustomer = type === "customer";

  const url = `/platform/contracts/${
    isCustomer
      ? `customer/${clientCognitoId}|${id || clientCognitoId}`
      : id || clientCognitoId
  }`;

  const { data } = await api.get(encodeURI(url));
  return data;
};

export const getContractsByContactId = async (id) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.get(
    `/platform/contracts/${clientCognitoId}/contact/${id}`
  );

  return data;
};

export const getContractById = async (contractId) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.get(
    `/platform/contracts/${clientCognitoId}/${contractId}`
  );

  return data;
};

export const createContract = async (payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.post(
    `/platform/contracts/${clientCognitoId}`,
    payload
  );
  return data;
};

export const updateContract = async (contractId, payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  if (!contractId) throw new Error("[API::CONTRACT::PATCH] Id is required.");

  const { data } = await api.patch(
    `/platform/contracts/${clientCognitoId}/${contractId}`,
    payload
  );

  return data;
};

export const deleteContract = async (contractId) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.delete(
    `/platform/contracts/${clientCognitoId}/${contractId}`
  );

  return data;
};

export const downloadContract = async (payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.post(
    `/settings/download_url/${clientCognitoId}`,
    payload
  );

  return data;
};

export const sendContractEmail = async (contractId) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.post(
    `/platform/contracts/${clientCognitoId}/send_email/${contractId}`,
    {}
  );

  return data;
};

export const createSmartContract = async (
  userId,
  contractText,
  invoiceId = null,
  contactId = null,
  uploadedDate = new Date()
) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }
  const payload = {
    uploaded_date: uploadedDate,
    contact_id: contactId,
    invoice_id: invoiceId,
    contract_text: contractText,
    is_smart_contract: true,
    contract_status: "pending",
  };

  const { data } = await fetch(
    `/platform/contracts/${clientCognitoId}/smart_contract`,
    payload
  );

  return data;
};

export const getContractMissingData = async (userId, contractText) => {
  const payload = {
    text: contractText,
  };

  const { data } = await api.post(
    `/platform/contracts/get_missing_info/${userId}`,
    payload
  );

  return data.field_list;
};

export const getInvoicesFromContract = async (userId, contractText) => {
  const payload = {
    text: contractText,
  };

  const { data } = await api.post(
    `/platform/contracts/get_invoices_from_contract/${userId}`,
    payload
  );

  return data;
};

export const changeContractStatus = async (
  contractId,
  contractStatus,
  clientId
) => {
  const payload = {
    contract_status: contractStatus,
    platform_id: clientId,
    contract_id: contractId,
  };

  const { data } = await api.patch(
    `/platform/contracts/customer/status`,
    payload
  );

  return data;
};
