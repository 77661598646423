import req from "api/config";
import axios from "axios";

/**
 * @typedef {Object} SuccessRes
 * @property {boolean} success  - The success status
 * @property {string} message   - The success message
 */

export const login = async (payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const options = {
    headers: {
      "x-api-key": process.env.REACT_APP_PBTECH_API_KEY,
    },
  };
  const { data } = await axios.post(
    `${process.env.REACT_APP_PBTECH_API_ENDPOINT}/${process.env.REACT_APP_PBTECH_API_VERSION}/auth/sign_in/${clientCognitoId}`,
    payload,
    options
  );
  // if (response.status === 403) {
  //   toast.error(res.detail);
  // }
  return data;
};

export const logout = async ({ cognitoId, token }) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const options = {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_PBTECH_API_KEY,
    },
  };
  const { data } = await axios.post(
    `${process.env.REACT_APP_PBTECH_API_ENDPOINT}/${process.env.REACT_APP_PBTECH_API_VERSION}/auth/sign_out/${clientCognitoId}/${cognitoId}?refresh_token=${token}`,
    {},
    options
  );

  return data;
};

/**
 * @param {Object} payload
 * @param {string} payload.previous_password    - The current password
 * @param {string} payload.new_password         - The new password
 * @param {string} payload.access_token         - The session token
 * @throws Will throw an error if status code is >= 400 and < 600. Should be handled by the caller.
 * @returns {Promise<SuccessRes>} A promise to the settings object
 */
export const resetPassword = async (payload) => {
  const { data } = await req.post(
    `${process.env.REACT_APP_PBTECH_API_VERSION}/auth/change_password`,
    payload
  );

  return data;
};
