import { logout } from "api/auth/auth";
import axios from "axios";

const baseURL = `${process.env.REACT_APP_PBTECH_API_ENDPOINT}/${process.env.REACT_APP_PBTECH_API_VERSION}`;
const api = axios.create({
  baseURL,
});

api.defaults.headers.post["Content-Type"] = "application/json";
api.defaults.headers.patch["Content-Type"] = "application/json";
api.defaults.headers.delete["Content-Type"] = "application/json";

// Auth request interceptor
api.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem("AccessToken"));
    if (token) {
      const token2 = token.replace(/"/g, "");
      config.headers.Authorization = `Bearer ${token2}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Auth response interceptor
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Check if error.response is defined
    if (error.response) {
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        const refreshToken = JSON.parse(localStorage.getItem("AuthToken")); // refresh token

        try {
          const response = await axios.post(`${baseURL}/auth/refresh-token`, {
            refreshToken,
          });
          const { token } = response.data;

          localStorage.setItem("AccessToken", JSON.stringify(token));
          const token2 = token.replace(/"/g, "");

          // Retry the original request with the new token
          originalRequest.headers.Authorization = `Bearer ${token2}`;
          return axios(originalRequest);
        } catch (error) {
          // Handle refresh token error or redirect to login
          // Logout then go to login page
          const userId = JSON.parse(localStorage.getItem("CognitoId"));
          logout({ cognitoId: userId, token: refreshToken })
            .then((data) => {
              localStorage.setItem("AuthToken", JSON.stringify(null));
              localStorage.setItem("AccessToken", JSON.stringify(null));
              localStorage.setItem("CognitoId", JSON.stringify(null));
              localStorage.setItem("UserRole", JSON.stringify(null));
              localStorage.setItem("pb_user", JSON.stringify(null));
              localStorage.setItem("tenancy_info", JSON.stringify(null));

              // I think it would still redirect without it
              window.location.href = `/login?expired=true`;
            })
            .catch((error) => {
              console.log(
                "API_V1::LOGOUT_AFTER_NO_REFRESH_TOKEN::ERROR::",
                error
              );
            });
        }
      }
    }

    return Promise.reject(error);
  }
);

export default api;
