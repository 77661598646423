import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CircularProgress, MenuItem, Select } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import Sidebar from "pages/sidebar/sidebar";
import ClientMainHeader from "pages/header";
import { StyledInput, StyledTextInput } from "pages/utils/styled-input";
import { validateEmail } from "scripts/functions";
import { createContact, getContactById, updateContact } from "./actions";
import { LoadingWrapper, MainWrapper } from "pages/styled";
import { FormBtnSection, FormLabel, FormSubLabel, OptionLabel } from "./styled";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { Button } from "components/ui/button-tw";
import { toast } from "react-toastify";
import { customTimeout } from "utils/functions";
import { BasicTooltip } from "ui";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import styled from "styled-components";
import { StyledSelect } from "pages/kyb/FormLabelStyles";
import { useFetchStates } from "hooks/useFetchStates";
import { LimitedAccountWarning } from "components/limited-account-warning";

const NewContact = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { settingData } = useSelector((state) => state.account);
  const {
    data: statesData,
    isLoading: statesLoading,
    error: statesError,
  } = useFetchStates("US");

  const [shortCut, setShortCut] = useState({
    label: "Create contact",
    value: "new-contact",
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [dbaName, setDbaName] = useState("");
  const [tag, setTag] = useState([]);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [note, setNote] = useState("");
  // const [isSubmitted, setIsSubmitted] = useState(false);
  const [validate, setValidate] = useState({});
  const [currentWidth, setCurrentWidth] = useState(0);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState(null);

  const queryString = window.location.search;

  useEffect(() => {
    const urlParam = new URLSearchParams(queryString).get("id");
    if (urlParam) {
      setSelectedContactId(urlParam);
      getContactInfo(urlParam);
    } else {
      setSelectedContactId(null);
    }
  }, [queryString]);

  const validatePersonalInfo = () => {
    let isValid = true;

    // Validate first name
    if (firstName === "") {
      setValidate((prevState) => ({ ...prevState, firstName: false }));
      isValid = false;
    } else {
      setValidate((prevState) => ({ ...prevState, firstName: true }));
    }

    // Validate last name
    if (lastName === "") {
      setValidate((prevState) => ({ ...prevState, lastName: false }));
      isValid = false;
    } else {
      setValidate((prevState) => ({ ...prevState, lastName: true }));
    }

    // Validate email
    if (email === "") {
      setValidate((prevState) => ({ ...prevState, email: false }));
      isValid = false;
    } else {
      if (validateEmail(email)) {
        setValidate((prevState) => ({ ...prevState, email: true }));
      } else {
        setValidate((prevState) => ({ ...prevState, email: false }));
        isValid = false;
      }
    }

    if (phone === "") {
      setValidate((prevState) => ({ ...prevState, phone: false }));
      isValid = false;
    } else {
      if (phone.length !== 10) {
        setValidate((prevState) => ({ ...prevState, phone: false }));
        isValid = false;
      } else {
        setValidate((prevState) => ({ ...prevState, phone: true }));
      }
    }

    return isValid;
  };

  console.log("CHECK", state);

  const getContactInfo = async (id) => {
    setLoading(true);
    await getContactById(id).then((res) => {
      setLoading(false);
      if (res && res.length > 0) {
        setFirstName(res[0].contact_info.first_name);
        setLastName(res[0].contact_info.last_name);
        setEmail(res[0].contact_info.email);
        setPhone(res[0].contact_info.phone);
        setBusinessName(res[0].contact_info.business_name);
        setDbaName(res[0].contact_info.business_dba);
        setTag(res[0].contact_info.tags);
        setAddress(res[0].contact_info.business_address);
        setCity(res[0].contact_info.business_city);
        setState(res[0].contact_info.business_state);
        setZipCode(res[0].contact_info.business_zip);
        setNote(res[0].contact_info.notes);
      }
    });
  };

  const handleCreateContact = async () => {
    const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
    if (!clientCognitoId) {
      throw new Error("It was not possible to identify the platform.");
    }

    if (validatePersonalInfo()) {
      const payload = {
        cognito_id: clientCognitoId,
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phone,
        tags: tag,
        business_name: businessName,
        business_dba: dbaName,
        business_address: address,
        business_city: city,
        business_state: state,
        business_zip: zipCode,
        business_country: "",
        notes: note,
        integrations: {},
      };
      setSubmitLoading(true);
      if (selectedContactId) {
        await updateContact(payload, selectedContactId)
          .then((res) => {
            if (res && res.status === "success") {
              toast.success("Your contact has been updated.");
              setSubmitLoading(false);
            }
          })
          .catch((err) => {
            console.error(err);
            setSubmitLoading(false);
          });
      } else {
        await createContact(payload)
          .then((res) => {
            if (res && res.status === "success") {
              queryClient.invalidateQueries(["contacts"]);
              toast.success("Your new contact has been created.");
              setSubmitLoading(false);
              customTimeout(() => {
                history.push("/contacts");
              });
            }
          })
          .catch((err) => {
            console.error(err);
            setSubmitLoading(false);
          });
      }
    } else {
      toast.error("Please fill the missing information");
    }
  };

  if (loading) {
    return (
      <LoadingWrapper>
        <CircularProgress disableShrink />
      </LoadingWrapper>
    );
  }

  return (
    <div>
      <Sidebar currentWidth={currentWidth} setCurrentWidth={setCurrentWidth} />
      <>
        <LimitedAccountWarning />

        <MainWrapper>
          <ClientMainHeader
            title="Contacts"
            subTitle="Create a contact"
            shortCut={shortCut}
            setShortCut={setShortCut}
          />
          <div className="pt-24 pb-25">
            <OptionLabel>Primary contact information</OptionLabel>
            <div className="flex mb-20 align-items-center flex-wrap gap-3 md:gap-0 md:flex-nowrap">
              <FormLabel>*Name</FormLabel>
              <div
                className="flex gap-3 flex-wrap md:flex-nowrap"
                style={{ width: "512px" }}
              >
                <StyledInput
                  value={firstName}
                  setValue={setFirstName}
                  placeholder={"First Name"}
                  validation={validate.firstName}
                />
                <StyledInput
                  value={lastName}
                  setValue={setLastName}
                  placeholder={"Last Name"}
                  validation={validate.lastName}
                />
              </div>
            </div>
            <div className="flex mb-20 align-items-center flex-wrap gap-3 md:gap-0">
              <FlexLabel>
                *Email Address
                <BasicTooltip
                  helperText={"Invoices will be sent to this email address."}
                  placement="right"
                >
                  <InfoOutlinedIcon fontSize="small" />
                </BasicTooltip>
              </FlexLabel>
              <StyledInput
                value={email}
                setValue={setEmail}
                placeholder={"Email"}
                width="512px"
                validation={validate.email}
              />
            </div>
            <div className="flex mb-20 align-items-center flex-wrap gap-3 md:gap-0">
              <FormLabel>*Phone number</FormLabel>
              <div style={{ width: "512px" }}>
                <PhoneInput
                  placeholder="Phone number"
                  country={"us"}
                  onlyCountries={["us"]}
                  value={phone}
                  style={{
                    border:
                      !validate.phone &&
                      validate.phone !== undefined &&
                      "1px solid #ff0000",
                  }}
                  onChange={(phone) => {
                    setPhone(phone);
                  }}
                  disableCountryCode
                />
              </div>
            </div>
          </div>
          <div className="pt-24 pb-25">
            <OptionLabel>Business contact information</OptionLabel>
            <div className="flex mb-20 align-items-center flex-wrap gap-3 md:gap-0">
              <FormLabel>Business name</FormLabel>
              <StyledInput
                value={businessName}
                setValue={setBusinessName}
                placeholder={"Business Name"}
                width="512px"
              />
            </div>
            <div className="flex mb-20 align-items-center flex-wrap gap-3 md:gap-0">
              <FormLabel>DBA</FormLabel>
              <StyledInput
                value={dbaName}
                setValue={setDbaName}
                placeholder={"Title"}
                width="512px"
              />
            </div>
            <div className="flex mb-20 align-items-center flex-wrap gap-3 md:gap-0">
              <FormLabel>Tags</FormLabel>
              <StyledInput
                value={tag}
                setValue={(value) => {
                  setTag([value]);
                }}
                placeholder={"Create a tag"}
                width="512px"
              />
            </div>
            <div className="flex mb-20 align-items-center flex-wrap gap-3 md:gap-0">
              <FormLabel>Physical Address</FormLabel>
              <StyledInput
                value={address}
                setValue={setAddress}
                placeholder={"Physical Address"}
                width="512px"
              />
            </div>
            <div className="flex mb-20 align-items-center flex-wrap gap-3 md:gap-0">
              <FormLabel>City</FormLabel>
              <StyledInput
                value={city}
                setValue={setCity}
                placeholder={"City"}
                width="512px"
              />
            </div>
            <div className="flex mb-20 align-items-center flex-wrap gap-3 md:gap-0">
              <FormLabel>State / Province</FormLabel>
              <div
                className="flex gap-3 flex-wrap md:flex-nowrap"
                style={{ width: "512px" }}
              >
                <CustomSelect
                  displayEmpty={true}
                  renderValue={(selected) => (selected ? selected : "State")}
                  variant="outlined"
                  onChange={(e) => setState(e.target.value)}
                >
                  {!statesLoading &&
                    !statesError &&
                    statesData.map((item) => (
                      <MenuItem value={item.value} key={item.label}>
                        {item.label}
                      </MenuItem>
                    ))}
                </CustomSelect>
                <StyledInput
                  value={zipCode}
                  setValue={setZipCode}
                  placeholder={"Zip Code"}
                  width="148px"
                />
              </div>
            </div>
            <div className="pt-24">
              <OptionLabel>Other</OptionLabel>
              <div className="flex flex-wrap gap-3 md:gap-0">
                <FormLabel>Notes</FormLabel>
                <StyledTextInput
                  value={note}
                  setValue={setNote}
                  width="512px"
                />
              </div>
            </div>
          </div>
          <FormBtnSection>
            <Button variant="outline" onClick={() => history.push("/contacts")}>
              Cancel
            </Button>
            <Button
              className="bg-skin-primary hover:bg-skin-primary/90 ml-2"
              onClick={handleCreateContact}
            >
              {selectedContactId ? "Update" : "Save"}
              {submitLoading && (
                <CircularProgress color="inherit" size={14} className="ml-08" />
              )}
            </Button>
          </FormBtnSection>
        </MainWrapper>
      </>
    </div>
  );
};

const FlexLabel = styled(FormLabel)`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const CustomSelect = styled(Select)`
  background-color: white;
  min-width: 146px;
  margin-right: 26px;
  border-radius: 8px;

  .MuiSelect-select {
    padding: 10px 14px;
  }

  &&.MuiOutlinedInput-root {
    border-radius: 8px !important;
  }
`;

export default NewContact;
