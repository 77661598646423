import { useQuery } from "@tanstack/react-query";
import { fetchPlatformData } from "api/v1-jwt/platform-data/platform-data";

export const usePlatformData = ({ queryFn, queryKey, ...rest } = {}) => {
  let host = window.location.host;
  let parts = host.split(".");

  let subdomain = process.env.REACT_APP_DEV_SUBDOMAIN || "unknown";
  if (parts.length >= 2 && !parts[0].startsWith("localhost")) {
    subdomain = parts[0];
  }

  return useQuery({
    queryKey: ["platform", subdomain],
    queryFn: () => fetchPlatformData(subdomain),
    // staleTime: Infinity,
    ...rest,
  });
};
