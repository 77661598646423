import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
// import moment from "moment";
import Sidebar from "pages/sidebar/sidebar";
import ClientMainHeader from "pages/header";
import WelcomeDialog from "./welcome-dialog/dialog";
import MobileViewDashboard from "pages/mobile-view/dashboard";
import { CustomSelector } from "pages/utils/styled-select";
import { chart_date_options, money_list } from "pages/demo";
import { convertThousandSeparator } from "scripts/functions";
import { MainWrapper } from "pages/styled";
import {
  ChartCard,
  ChartRadioLabel,
  DateOptionBtn,
  FeatureCard,
  FeatureCardBalance,
  FeatureCardLabel,
  InvoiceChartTitle,
  PaymentCardSection,
  RedirectBtnSection,
  SmallCard,
  FeatureCardBalanceDetail,
  StyledSelectedRadio,
  MainLabel,
  FeatureBalanceCard,
} from "./styled";
import { getWelcomeName } from "./utils";
import { InvoiceChart } from "./components";
import { useSelector } from "react-redux";
import { useBondBalance, useInvoiceKpis, useProfileInfo } from "./hooks";
import { useFetchKYBData } from "./hooks/useFetchKYBData";
import { CircularProgress, MenuItem, Select } from "@mui/material";
import styled from "styled-components";
import useGetClientInfo from "./hooks/useGetClientInfo";
import { LimitedAccountWarning } from "components/limited-account-warning";
import { useAuth } from "providers/auth";
import { useKYBData } from "hooks/platform/use-kyb-data";
import { BiLock } from "react-icons/bi";
import { Check, Loader2 } from "lucide-react";
import { cn } from "utils/classnames";
import { Button } from "components/ui/button-tw";
import { useSubscription } from "hooks/subscription/useGetSubscription";
import { useGetPaymentMethods } from "hooks/payment/use-get-payment-methods";

import { useTenancy } from "hooks/tenancy/use-tenancy";
import usePlaidLinkWithStatus from "pages/plaid/hooks/usePlaidLink";
import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { Plaid } from "components/payment-methods";
import { updateTenancyInfo } from "api/v1-jwt/tenancy/tenancy";

const Dashboard = () => {
  const history = useHistory();
  const [dateOption, setDateOption] = useState(30);
  const {
    invoiceChartData,
    totalAmount,
    paidAmount,
    unpaidAmount,
    pendingAmount,
    totalCount,
    paidCount,
    unpaidCount,
    dateForAmount,
    totalValueForAmount,
    paidValueForAmount,
    unpaidValueForAmount,
    dateForCount,
    totalValueForCount,
    paidValueForCount,
    unpaidValueForCount,
    pendingCount,
  } = useInvoiceKpis(dateOption);

  const { profileInfo, welcomeDialog, openWelcomeDialog } = useProfileInfo();
  const bondBalance = useBondBalance();

  const { settingData } = useSelector((state) => state.account);

  const [shortCut, setShortCut] = useState("");
  const [type, setType] = useState({ label: "Invoices", value: "invoices" });
  const [kpiOption, setKpiOption] = useState({
    label: "Dollars",
    value: "dollars",
  });

  const [paymentOption, setPaymentOption] = useState("created");

  const [currentWidth, setCurrentWidth] = useState(0);

  const totalData = () => {
    if (paymentOption === "created") {
      if (kpiOption.value === "dollars") {
        return totalValueForAmount;
      } else {
        return totalValueForCount;
      }
    } else {
      return [];
    }
  };

  const paidData = () => {
    if (paymentOption === "paid") {
      if (kpiOption.value === "dollars") {
        return paidValueForAmount;
      } else {
        return paidValueForCount;
      }
    } else {
      return [];
    }
  };

  const unpaidData = () => {
    if (paymentOption === "unpaid") {
      if (kpiOption.value === "dollars") {
        return unpaidValueForAmount;
      } else {
        return unpaidValueForCount;
      }
    } else {
      return [];
    }
  };

  const chartOptions = {
    grid: { top: 8, right: 8, bottom: 24, left: 70 },
    xAxis: {
      type: "category",
      data: kpiOption.value === "dollars" ? dateForAmount : dateForCount,
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: kpiOption.value === "dollars" ? "$ {value}" : "{value}",
      },
    },
    series: [
      {
        data: totalData(),
        type: "line",
        showSymbol: false,
        lineStyle: { color: "#000000" },
      },
      {
        data: paidData(),
        type: "line",
        showSymbol: false,
        lineStyle: { color: "#939393" },
      },
      {
        data: unpaidData(),
        type: "line",
        showSymbol: false,
        lineStyle: { color: "#e1e1e1" },
      },
    ],
    tooltip: {
      trigger: "axis",
    },
  };

  return (
    <div>
      <WelcomeDialog dialog={welcomeDialog} setDialog={openWelcomeDialog} />
      <Sidebar currentWidth={currentWidth} setCurrentWidth={setCurrentWidth} />
      {currentWidth < 768 ? (
        <MobileViewDashboard
          type={type}
          setType={setType}
          bondBalance={bondBalance}
          businessName={getWelcomeName(profileInfo)}
          kpiOption={kpiOption}
          setKpiOption={setKpiOption}
          dateOption={dateOption}
          setDateOption={setDateOption}
          chartOptions={chartOptions}
          invoiceChartData={invoiceChartData}
        />
      ) : (
        <>
          <LimitedAccountWarning />
          <MainWrapper>
            <ClientMainHeader
              title="Dashboard"
              subTitle={`Welcome ${getWelcomeName(profileInfo)}`}
              shortCut={shortCut}
              setShortCut={setShortCut}
            />

            <LinkBankStepper />

            <div>
              <MainLabel>Summary</MainLabel>
              <div className="flex">
                <CustomSelector
                  value={type}
                  setValue={setType}
                  selectList={[{ label: "Invoices", value: "invoices" }]}
                  width={150}
                  menuWidth={230}
                  mBottom={16}
                  mRight={16}
                  menuLeft
                />
                <CustomSelector
                  value={kpiOption}
                  setValue={setKpiOption}
                  selectList={money_list}
                  width={120}
                  menuWidth={240}
                  mBottom={16}
                  menuLeft
                />
              </div>
              <PaymentCardSection>
                <FeatureCard style={{ width: 238, height: 112 }}>
                  <div>
                    <FeatureCardLabel>Created</FeatureCardLabel>
                    <FeatureCardBalance>
                      {kpiOption.value === "dollars"
                        ? `$${convertThousandSeparator(totalAmount)}`
                        : totalCount}
                    </FeatureCardBalance>
                  </div>
                </FeatureCard>
                <FeatureCard style={{ width: 238, height: 112 }}>
                  <div>
                    <FeatureCardLabel>Paid + Processing</FeatureCardLabel>
                    <FeatureCardBalance>
                      {kpiOption.value === "dollars"
                        ? `$${convertThousandSeparator(
                            paidAmount + pendingAmount
                          )}`
                        : paidCount}
                      <FeatureCardBalanceDetail
                        kpiOption={kpiOption.value === "dollars"}
                        paid={
                          kpiOption.value === "dollars" ? paidAmount : paidCount
                        }
                        processing={
                          kpiOption.value === "dollars"
                            ? pendingAmount
                            : pendingCount
                        }
                      />
                    </FeatureCardBalance>
                  </div>
                </FeatureCard>
                <FeatureCard style={{ width: 238, height: 112 }}>
                  <div>
                    <FeatureCardLabel>Unpaid</FeatureCardLabel>
                    <FeatureCardBalance>
                      {kpiOption.value === "dollars"
                        ? `$${convertThousandSeparator(unpaidAmount)}`
                        : unpaidCount}
                    </FeatureCardBalance>
                  </div>
                </FeatureCard>
              </PaymentCardSection>
              <ChartCard>
                <InvoiceChartTitle>Invoices</InvoiceChartTitle>
                <div className="flex justify-between align-items-center pb-25">
                  <div className="flex">
                    {chart_date_options.map((option) => (
                      <div key={option.value}>
                        {dateOption === option.value ? (
                          <DateOptionBtn style={{ background: "#e1e1e1" }}>
                            {option.label}
                          </DateOptionBtn>
                        ) : (
                          <DateOptionBtn
                            onClick={() => setDateOption(option.value)}
                          >
                            {option.label}
                          </DateOptionBtn>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="flex">
                    {/* {payment_options.map((option, index) =>
                    paymentOption === option.value ? (
                      <div
                        key={index}
                        className="flex align-items-center mr-20 cursor-pointer"
                      >
                        <StyledSelectedRadio />
                        <ChartRadioLabel>{option.label}</ChartRadioLabel>
                      </div>
                    ) : (
                      <div
                        key={index}
                        className="flex align-items-center mr-20 cursor-pointer"
                        onClick={() => setPaymentOption(option.value)}
                      >
                        <StyledRadio />
                        <ChartRadioLabel>{option.label}</ChartRadioLabel>
                      </div>
                    )
                  )} */}
                    <div
                      key={"button-paid"}
                      className="flex align-items-center mr-20 cursor-pointer"
                    >
                      <StyledSelectedRadio
                        primaryColor={settingData?.primary_color}
                        status="paid"
                      />
                      <ChartRadioLabel>Paid</ChartRadioLabel>
                    </div>
                    <div
                      key={"button-pendind-and-paid"}
                      className="flex align-items-center mr-20 cursor-pointer"
                    >
                      <StyledSelectedRadio
                        primaryColor={settingData?.primary_color}
                        status="processingandpaid"
                      />
                      <ChartRadioLabel>Processing + Paid</ChartRadioLabel>
                    </div>
                    <div
                      key={"button-unpaid"}
                      className="flex align-items-center mr-20 cursor-pointer"
                    >
                      <StyledSelectedRadio
                        primaryColor={settingData?.primary_color}
                        status="unpaid"
                      />
                      <ChartRadioLabel>Unpaid</ChartRadioLabel>
                    </div>
                  </div>
                </div>
                <InvoiceChart
                  primaryColor={settingData?.primary_color}
                  invoiceData={invoiceChartData}
                />
              </ChartCard>
            </div>
          </MainWrapper>
        </>
      )}
    </div>
  );
};

export function LinkBankStepper() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { user, userRole } = useAuth();
  const { data: tenancy, isLoading: isTenancyLoading } = useTenancy();
  const { data: subscription } = useSubscription();
  const { data: paymentMethods, isLoading: paymentMethodsLoading } =
    useGetPaymentMethods();
  const [plaidOpen, setPlaidOpen] = useState(false);

  const plaid = paymentMethods?.find(
    (payment) => payment.payment_method_type === "PLAID"
  );

  const togglePlaid = () => {
    setPlaidOpen((prev) => !prev);
  };

  const handlePlaidSuccess = async (_paymentMethod, _accessToken, metadata) => {
    const verificationStatus = metadata?.account?.verification_status;
    let recordStatusUpdate = undefined;
    if (
      verificationStatus === "pending_automatic_verification" ||
      verificationStatus === "pending_manual_verification"
    ) {
      recordStatusUpdate = "plaid_micro_deposit";
    } else if (
      verificationStatus === "automatically_verified" ||
      verificationStatus === "manually_verified" ||
      !verificationStatus
    ) {
      recordStatusUpdate = "kyb_pending";
    }

    console.log("METADATA::", metadata);
    if (recordStatusUpdate) {
      try {
        console.log("UPDATING_RECORD_STATUS::", recordStatusUpdate);
        await updateTenancyInfo({
          record_status: recordStatusUpdate,
        });
      } catch (error) {
        console.log("UPDATE_RECORD_STATUS_ERROR::", error);
      }
    }

    queryClient.invalidateQueries(["payment_methods"]);
    queryClient.invalidateQueries(["tenancy"]);
  };

  const trialDaysLeft = useMemo(
    () => 14 - dayjs().diff(subscription?.trial_start_date || dayjs(), "day"),
    [subscription]
  );

  if (paymentMethodsLoading || isTenancyLoading) {
    return (
      <div
        role="status"
        className="max-w-[750px] p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
      >
        <div className="flex items-center justify-between">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
        </div>
        <div className="flex items-center justify-between pt-4">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
        </div>

        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  {
    /** plaid_pending - plaid_micro_deposit - kyb_pending - active */
  }
  if (tenancy?.record_status !== "active") {
    return (
      <div className="max-w-[750px] bg-white rounded-xl lg:border-b lg:border-t lg:border-gray-200 mb-12">
        <nav className=" max-w-3xl " aria-label="Progress">
          <ol
            role="list"
            className="overflow-hidden rounded-xl lg:flex lg:border-l lg:border-r lg:border-gray-200"
          >
            <li className="relative overflow-hidden lg:flex-1">
              <div
                className={cn(
                  "rounded-t-md border-b-0",
                  "overflow-hidden border border-gray-200 lg:border-0"
                )}
              >
                {tenancy?.record_status !== "plaid_pending" &&
                tenancy?.record_status !== "plaid_micro_deposit" ? (
                  <a className="group">
                    <span
                      className="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                      aria-hidden="true"
                    />
                    <span
                      className={cn(
                        "flex items-start px-6 py-5 text-sm font-medium"
                      )}
                    >
                      <span className="flex-shrink-0">
                        <span className="flex h-10 w-10 items-center justify-center rounded-full bg-skin-primary">
                          <Check
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      </span>
                      <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                        <span className="text-sm font-medium">
                          Link your bank account
                        </span>
                        <span className="text-sm font-medium text-gray-500">
                          Continue using Pocketbook after the trial period ends.
                        </span>
                      </span>
                    </span>
                  </a>
                ) : (
                  <a aria-current="step">
                    <span
                      className="absolute left-0 top-0 h-full w-1 bg-skin-primary lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                      aria-hidden="true"
                    />
                    <span
                      className={cn(
                        "flex items-start px-6 py-5 text-sm font-medium"
                      )}
                    >
                      <span className="flex-shrink-0">
                        <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-skin-primary">
                          <span className="text-skin-primary">01</span>
                        </span>
                      </span>
                      <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                        <span className="text-sm font-medium text-skin-primary">
                          Link your bank account
                        </span>
                        <span className="text-sm font-medium text-gray-500">
                          Continue using Pocketbook after the trial period ends.
                          You have{" "}
                          {trialDaysLeft <= 5 ? (
                            <span className="text-red-600">
                              {trialDaysLeft < 0 ? 0 : trialDaysLeft}
                            </span>
                          ) : (
                            <span>{trialDaysLeft}</span>
                          )}{" "}
                          days left.
                        </span>
                        {userRole === "admin" ? (
                          <span className="text-sm font-medium text-gray-500">
                            This action must be started by the account owner.
                          </span>
                        ) : (
                          <Button
                            variant="secondary"
                            onClick={() => togglePlaid()}
                            className="mt-2"
                            disabled={plaidOpen}
                          >
                            {plaidOpen && (
                              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            )}
                            {tenancy?.record_status === "plaid_micro_deposit"
                              ? "Click to verify"
                              : "Start"}
                          </Button>
                        )}
                      </span>
                    </span>
                  </a>
                )}
              </div>
            </li>
            <li className="relative overflow-hidden lg:flex-1">
              <div
                className={cn(
                  "rounded-b-md border-t-0",
                  "overflow-hidden border border-gray-200 lg:border-0"
                )}
              >
                {false ? (
                  <a className="group">
                    <span
                      className="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                      aria-hidden="true"
                    />
                    <span
                      className={cn(
                        "lg:pl-9",
                        "flex items-start px-6 py-5 text-sm font-medium"
                      )}
                    >
                      <span className="flex-shrink-0">
                        <span className="flex h-10 w-10 items-center justify-center rounded-full bg-skin-primary">
                          <Check
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      </span>
                      <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                        <span className="text-sm font-medium">
                          Receive payments
                        </span>
                        <span className="text-sm font-medium text-gray-500">
                          Set up your processing account in minutes.
                        </span>
                      </span>
                    </span>
                  </a>
                ) : tenancy?.record_status === "kyb_pending" ? (
                  <a aria-current="step">
                    <span
                      className="absolute left-0 top-0 h-full w-1 bg-skin-primary lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                      aria-hidden="true"
                    />
                    <span
                      className={cn(
                        "lg:pl-9",
                        "flex items-start px-6 py-5 text-sm font-medium"
                      )}
                    >
                      <span className="flex-shrink-0">
                        <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-skin-primary">
                          <span className="text-skin-primary">02</span>
                        </span>
                      </span>
                      <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                        <span className="text-sm font-medium text-skin-primary">
                          Receive payments
                        </span>
                        <span className="text-sm font-medium text-gray-500">
                          Set up your processing account in minutes.
                        </span>
                        {userRole === "admin" ? (
                          <span className="text-sm font-medium text-gray-500">
                            This action must be started by the account owner.
                          </span>
                        ) : (
                          <Button
                            className="mt-2"
                            variant="secondary"
                            onClick={() => history.push("/kyb")}
                          >
                            Start
                          </Button>
                        )}
                      </span>
                    </span>
                  </a>
                ) : (
                  <a className="group">
                    <span
                      className="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                      aria-hidden="true"
                    />
                    <span
                      className={cn(
                        "lg:pl-9",
                        "flex items-start px-6 py-5 text-sm font-medium"
                      )}
                    >
                      <span className="flex-shrink-0">
                        <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300">
                          <span className="text-gray-500">02</span>
                        </span>
                      </span>
                      <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                        <span className="text-sm font-medium text-gray-500">
                          Receive payments
                        </span>
                        <span className="text-sm font-medium text-gray-500">
                          Set up your processing account in minutes.
                        </span>
                      </span>
                    </span>
                  </a>
                )}

                <>
                  {/* Separator */}
                  <div
                    className="absolute inset-0 left-0 top-0 hidden w-3 lg:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="h-full w-full text-gray-300"
                      viewBox="0 0 12 82"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0.5 0V31L10.5 41L0.5 51V82"
                        stroke="currentcolor"
                        vectorEffect="non-scaling-stroke"
                      />
                    </svg>
                  </div>
                </>
              </div>
            </li>
          </ol>
        </nav>
        {plaidOpen ? (
          <Plaid
            dashboard
            open={plaidOpen}
            onClose={() => setPlaidOpen(false)}
            method_type={plaid?.method_type || "PLAID"}
            access_token={plaid?.payment_method_data?.access_token || ""}
            onSuccess={handlePlaidSuccess}
            payment_method_status={plaid?.payment_method_status || ""}
            name_on_account={plaid?.payment_method_data?.name_on_account || ""}
          />
        ) : null}
      </div>
    );
  }
  // const { linkToken, loadingToken, error, openPlaidLink, readyPlaidLink } =
  //   usePlaidLinkWithStatus({
  //     payment_method_status: plaid?.payment_method_status || "",
  //     method_type: plaid?.method_type || "PLAID",
  //     access_token: plaid?.payment_method_data?.access_token || "",
  //     onSuccess: () => queryClient.invalidateQueries(["payment_methods"]),
  //   });
  return (
    <div className="flex pb-38">
      <FeatureBalanceCard />
      <RedirectBtnSection>
        <SmallCard onClick={() => history.push("/invoices/new")}>
          Create an invoice
        </SmallCard>
        <SmallCard onClick={() => history.push("/new-contact")}>
          Add a contact
        </SmallCard>
        <SmallCard onClick={() => history.push("/smart-quotes")}>
          Add a quote
        </SmallCard>
        <SmallCard
          onClick={() =>
            user?.cognito_user_info?.UserRole === "owner" &&
            history.push("/move-money")
          }
        >
          {user?.cognito_user_info?.UserRole === "admin" && (
            <BiLock className="w-5 h-5 mr-2" />
          )}
          Move money{" "}
        </SmallCard>
      </RedirectBtnSection>
    </div>
  );
}

export default Dashboard;
